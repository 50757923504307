import { getTimezoneOffset } from 'date-fns-tz'

export const formatTimeZoneLabel = (timezone: string) => {
  const offset = getTimezoneOffset(timezone)

  const offsetHours = Math.abs(Math.floor(offset / (60 * 60 * 1000)))
  const offsetSign = offset >= 0 ? '+' : '-'

  const label = `(GMT ${offsetSign}${offsetHours.toString().padStart(2, '0')}:00) ${timezone.replace(/_/g, ' ')}`

  return label
}

export const getTimezoneOptions = () => {
  const timezones = Intl.supportedValuesOf('timeZone').map((timezone) => {
    const offset = getTimezoneOffset(timezone)
    return { timezone, offset }
  })

  timezones.sort((a, b) => a.offset - b.offset)

  return timezones.map(({ timezone }) => ({
    label: formatTimeZoneLabel(timezone),
    value: timezone,
  }))
}

export const getDeviceTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}
